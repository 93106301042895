/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "./layouts/Admin.js";
import SplashLayout from "./layouts/Splash.js";
import Fraud from "./layouts/Fraud";
import AdverseLayout from "./layouts/Adverse.js";
import MultipleLoans from './layouts/MultipleLoans.js';

import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "../src/assets/css/root.css";
import { useAuth0 } from "@auth0/auth0-react";
import FcRoutes from "./services/eventService";
import LoadingMsg from "./components/LoadingMsg";
import ErrorScreen from "./views/components/Error-Screen";

// Utils
import { auxShowErrorScreen } from "./utils";
import SocialMediaIcons from "./views/components/SocialMediaIcons";

const App = () => {
  const [token, setToken] = useState(null);
  const [myUser, setMyUser] = useState(null);
  const [loanFile, setLoanFile] = useState(null);
  const [loanFileErr, setLoanFileErr] = useState(false);
  const [isFraud, setIsFraud] = useState(false);
  const [identityItem, setIdentityItem] = useState(null);
  const [identityVerificationId, setIdentityVerificationId] = useState(null);
  const [identityError, setIdentityError] = useState(false);
  const [myLoading, setMyLoading] = useState(true);
  const [closingLink] = useState(null);
  const [roomInfo] = useState(null);
  const [disclosuresSpinner, setDisclosuresSpinner] = useState(false);
  const [messageCount, setMessageCount] = useState(null);
  const [messages] = useState([]);
  const [loanTeam] = useState([]);
  const [loadingChat] = useState(false);
  const [hasAllIncompleteLoans, setHasAllIncompleteLoans] = useState(false);
  const TemplateConfig = useSelector((state) => state.TemplateConfig);

  let apiClient = null;
  const getAccessToken = async () => {
    const token = await getAccessTokenSilently();
    return token;
  };
  const savedLoanCard = JSON.parse(localStorage.getItem('selectedLoan'));
  const loanId = savedLoanCard?.loanId ?? loanFile?.[0]?.loanId;

  useEffect(() => {
    console.log("TEST_REACT_APP_API_KEY", process.env.TEST_REACT_APP_API_KEY);
  }, []);

  React.useEffect(async () => {
    let isMounted = true;
    setToken(await getAccessToken());
    if (token) {
      apiClient = new FcRoutes(token);
    }
    if (!myUser) {
      if (token) {
        apiClient
          .getLoans()
          .then((res) => {
            if(res.data == null || res?.data?.length === 0){
              setHasAllIncompleteLoans(true);
            }
            if (isMounted) {
              setMyUser(res.data);
              setIdentityVerificationId(res.data?.user_metadata?.eventId);
            }
          })
          .catch((err) => {
            console.error("error in setUser", err);
            setMyLoading(false);
            setLoanFileErr(true);
          });
      }
    } else {
      if (token) {
        if (!loanFile) {
          apiClient
            .getLoans()
            .then((res) => {
              if (isMounted) {
                setLoanFile(res.data);
                let { firstName, lastName, birthDate, phoneNumber } = res?.data?.[0].borrowers[0];
                let idItem = {
                  fileId: loanId,
                  borrower: {
                    borrowerFirstName: firstName,
                    borrowerLastName: lastName,
                    borrowerDOB: birthDate,
                    borrowerPrimaryPhone: phoneNumber,
                  },
                  borrowerProperty: {
                    propertyStreetAddress: res?.data?.[0].property?.streetAddress,
                    propertyCity: res?.data?.[0].property?.city,
                    propertyState: res?.data?.[0].property?.state,
                    propertyCounty: res?.data?.[0].property?.county,
                    propertyZip: res?.data?.[0].property?.postalCode,
                  },
                  previousReferenceId: loanFile?.user_metadata?.previousReferenceId,
                };
                if (phoneNumber) {
                  setIdentityItem(idItem);
                } else {
                  setIdentityError(true);
                }
                setMyLoading(false);
              } else {
                setMyLoading(false);
              }
              if(res.data == null || res?.data?.length === 0){
                setHasAllIncompleteLoans(true);
              }
              let emails = res.data?.[0].borrowers?.map(
                (x) => x.borrowerEmailAddress
              );
              let addSigningRoomRequestItem = {};
              if (emails.length > 0) {
                addSigningRoomRequestItem = {
                  loanId,
                  type: "Initial",
                  emails: res.data?.[0].borrowers?.map(
                    (x) => x.borrowerEmailAddress
                  ),
                };
              } else {
                addSigningRoomRequestItem = {
                  loanId,
                  type: "Initial",
                };
              }
              console.log(
                "addSigningRoomRequestItem",
                addSigningRoomRequestItem
              );
              if (loanFile?.app_metadata?.roomInfo) setDisclosuresSpinner(true);
            })
            .catch((err) => {
              setLoanFileErr(true);
              setMyLoading(false);
            });
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [token, myUser]);

  const {
    isAuthenticated,
    isLoading,
    error,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  if (isLoading) {
    return (
      <LoadingMsg
        type="Splash"
        message="Hold on while we authenticate your login."
      />
    );
  }

  if (error) {
    return <div>Error: {error.message} Please contact support.</div>;
  }

  // waits till loanFile loads, else we get flickering of routes
  if (loanFile === null && !isLoading && isAuthenticated && !loanFileErr && !hasAllIncompleteLoans ) {
    return <LoadingMsg type="Splash" message="Getting loan file." />;
  
  }else if(hasAllIncompleteLoans === true){
    return <LoadingMsg type='NoEligibleLoans' message={TemplateConfig?.errorMessages?.incompleteLoansError?.body} />;
  }

  const determineRoutes = () => {
    if (auxShowErrorScreen) {
      return (
        <div className="error_screen_container">
          <div className="error_screen_bar">
            <div className="error_screen_logo_container">
              <img src="logo" alt="logo" className="logo_error_screen" />
            </div>
          </div>
          <div className="error_screen_content">
            <div className="error_screen_title">What happened?...</div>
            <div className='error_screen_body'>
              Please email{' '}
              <a className='error_screen_email' href={`mailto:${adminNavbar?.emailContact}`} target='_blank'>
                {adminNavbar?.emailContact}
              </a>{' '}
              or
              <br />
              call {adminNavbar?.phoneContact} for assistance
            </div>
            <a href={adminNavbar?.returnLink} className='error_screen_button'>
              {adminNavbar?.returnLinkText}
            </a>
          </div>
          <SocialMediaIcons />
        </div>
      );
    }
    //It"s important that fraud is checked for first, as there could be adverse action on the loan as well, so need to catch fraud upfront.
    if (loanFile?.loanStatus?.statusName == "FRAUD" || isFraud) {
      return (
        <>
          <Route
            path="/contact"
            render={(props) => (
              <Fraud
                {...props}
                user={myUser}
                loanFile={loanFile}
                loanFileErr={loanFileErr}
                idItem={identityItem}
                idErr={identityError}
                identityVerificationId={identityVerificationId}
                setIdentityVerificationId={setIdentityVerificationId}
                token={token}
              />
            )}
          />
          <Redirect to="/contact" />
        </>
      );
    } else if (loanFileErr) {
      return <ErrorScreen />;
    } else if (
      loanFile?.loanStatus?.hasAdverseAction ||
      myUser?.app_metadata?.isAdverse
    ) {
      return (
        <>
          {loanFileErr ? (
            <></>
          ) : (
            <>
              <Route
                path="/adverse"
                render={(props) => (
                  <AdverseLayout
                    {...props}
                    loading={myLoading}
                    user={myUser}
                    loanFile={loanFile}
                    loanFileErr={loanFileErr}
                    idItem={identityItem}
                    idErr={identityError}
                    closingLink={closingLink}
                    token={token}
                  />
                )}
              />
              <Redirect to="/adverse" />
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <Route
            exact
            path="/splash"
            render={(props) => (
              <SplashLayout
                {...props}
                loading={myLoading}
                user={myUser}
                loanFile={loanFile}
                loanFileErr={loanFileErr}
                idItem={identityItem}
                idErr={identityError}
                closingLink={closingLink}
                loanId={loanId}
                //setHasVisitedSplashPage={setHasVisitedSplashPage}
              />
            )}
            />
          <Route
            exact
            path='/multiple/loans'
            render={(props) => (
              <MultipleLoans
                {...props}
                user={myUser}
                loanFile={loanFile}
                loanFileErr={loanFileErr}
              />
            )}
          />
          <Route
            path="/admin"
            render={(props) => (
              <AdminLayout
                {...props}
                loading={myLoading}
                user={myUser}
                loanFile={loanFile}
                loanFileErr={loanFileErr}
                setIdentityVerificationId={setIdentityVerificationId}
                identityVerificationId={identityVerificationId}
                setIsFraud={setIsFraud}
                idItem={identityItem}
                idErr={identityError}
                closingLink={closingLink}
                token={token}
                roomInfo={roomInfo}
                disclosuresSpinner={disclosuresSpinner}
                messages={messages}
                messageCount={messageCount}
                loanTeam={loanTeam}
                loadingChat={loadingChat}
                setMessageCount={setMessageCount}
              />
            )}
          />

          {(
            <>
              {(loanFile.length > 1 && !savedLoanCard) ? ( <Redirect to="/multiple/loans" /> ) : <Redirect to="/admin/loan-info" />  }
            </>
          )}
        </>
      );
    }
  };

  return (
    <>
      {loanFileErr ? (
        <ErrorScreen />
      ) : (
        <BrowserRouter basename={`${process.env.REACT_APP_HOMEPAGE_PATH}`}>
          <Switch>
            {isAuthenticated ? (
              determineRoutes()
            ) : (
              <Redirect to={loginWithRedirect()} from="*" />
            )}
          </Switch>
        </BrowserRouter>
      )}
    </>
  );
};

export default App;

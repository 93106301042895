/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from 'react';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import FcRoutes from '../services/eventService';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingMsg from '../components/LoadingMsg';
import CodeModal from '../components/CodeModal/CodeModal';
import { useSelector } from 'react-redux';

function Disclosures(props) {
  const getAccessToken = async () => {
    const token = await getAccessTokenSilently();
    return token;
  };

  const { getAccessTokenSilently } = useAuth0();

  const [clicked, setClicked] = React.useState(false);
  const [lat, setLat] = React.useState('');
  const [packageId, setPackageId] = React.useState('');
  const [putFrame, setPutFrame] = React.useState(false);
  const [authenticationCode, setAuthenticationCode] = React.useState();
  const [showCodeModal, setShowCodeModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [response, setResponse] = React.useState({});
  const { siteId } = useSelector((state) => state?.TemplateConfig?.features?.borrowerPortal);
  const savedLoanCard = JSON.parse(localStorage.getItem('selectedLoan'));
  const obtainWidget = async () => {
    const apiClient = new FcRoutes(await getAccessToken());
    setIsLoading(true);
    apiClient
      .getToken(savedLoanCard?.loanId)
      .then((resp) => {
        setIsLoading(false);
        console.log(resp?.data);
        setLat(resp?.data?.latId);
        setPackageId(resp?.data?.packageId);
        setResponse(resp?.data);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    obtainWidget();
  }, []);

  const onClickGetRoom = async (e) => {
    e.preventDefault();
    setClicked(true);
    setPutFrame(true);
    if (response.authenticationCode) {
      setAuthenticationCode(response.authenticationCode);
      setShowCodeModal(true);
    }
  };

  const Iframe = () => {
    return (
      <div className='container-iframe'>
        <div
          class='em-widget'
          data-width='100%'
          data-height='800px'
          data-min-height='800px'
          data-site-id={`${siteId}`}
          data-config={`{"authentication":"","token":"${lat}","packageId":"${packageId}"}`}
          data-name='ecc-borrower-portal'
          data-aria-label='Borrower Portal'
        />
      </div>
    );
  };

  if (props.loading || isLoading) {
    return <LoadingMsg />;
  }

  if (!lat && !packageId) {
    return (
      <div className='content'>
        <Card className='setSizeW'>
          <CardBody>
            <span className='large_purple'>
              <i className='fa-regular fa-info-circle'></i>
            </span>{' '}
            <br />
            <CardTitle>Disclosures</CardTitle>
            <CardText>There are currently no documents ready to review</CardText>
            <button className='button disabled noClick' id='1' disabled style={{ color: 'white' }}>
              Review Documents
            </button>
          </CardBody>
        </Card>
      </div>
    );
  } else {
    return (
      <>
        <div className='content'>
          {!clicked && (
            <Card className='setSizeM'>
              <CardBody>
                <span className='large_purple'>
                  <i className='fa-regular fa-circle-exclamation'></i>
                </span>{' '}
                <br />
                <CardTitle>Disclosures</CardTitle>
                <CardText>
                  To get started please click <b>Review Documents</b> below. We'll set up a secure signing room for you to review and sign the
                  disclosures.
                </CardText>
                {clicked ? (
                  <button className='button blue_style' id='1' disabled style={{ color: 'grey' }}>
                    Review Documents
                  </button>
                ) : (
                  <button
                    className='button blue_style'
                    id='1'
                    onClick={(e) => {
                      onClickGetRoom(e);
                    }}>
                    Review Documents
                  </button>
                )}
              </CardBody>
            </Card>
          )}
          {putFrame ? <Iframe info={props.roomInfo} /> : null}
          {!putFrame && clicked && <LoadingMsg type='Splash' message='Hold on while we authenticate.' />}
          <CodeModal close={() => setShowCodeModal(false)} isOpen={showCodeModal} authenticationCode={authenticationCode} />
        </div>
      </>
    );
  }
}

export default Disclosures;

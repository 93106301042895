import React from "react";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import LogoutButton from "./logout-button";
function LoadingMsg(props) {
  const message = props.message
    ? props.message
    : "Pulling up your loan info...";
  const statusResponse = (type) => {
    switch (type) {
      case "PreClosing":
        return (
          <>
            <Card className="setSizeM m-auto">
              <CardBody>
                <span className="large_purple">
                  <i className="fa-solid fa-spinner fa-spin"></i>
                </span>
                <br />
                <CardText>{message}</CardText>
              </CardBody>
            </Card>
          </>
        );
      case "Splash":
        return (
          <>
            <Card
              className="setSizeM m-auto"
              style={{ border: "none", boxShadow: "none" }}
            >
              <CardBody>
                <span className="large_purple">
                  <i className="fa-solid fa-spinner fa-spin fa-4x"></i>
                </span>
                <br />
                <CardText style={{ fontSize: "18px" }}>{message}</CardText>
              </CardBody>
            </Card>
          </>
        );
      case "NoEligibleLoans":
        const finalMessage = message === "Pulling up your loan info..." ? "No eligible loans were found." : message;
        return (
          <>
            <Card className="setSizeM m-auto">
              <CardBody>
                <span className="large_purple">
                  <i className="fas fa-circle-ellipsis"></i>
                </span>
                <br />
                <CardText>{finalMessage}</CardText>
                <div className='button-container'>
                  <LogoutButton />
                </div>
              </CardBody>
            </Card>
          </>
        );
      default:
        return (
          <>
            <Card className="setSizeM">
              <CardBody>
                <span className="large_purple">
                  <i className="fas fa-circle-ellipsis"></i>
                </span>
                <br />
                <CardText>{message}</CardText>
              </CardBody>
            </Card>
          </>
        );
    }
  };
  return <div className="content">{statusResponse(props.type)}</div>;
}
export default LoadingMsg;

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
	const { logout } = useAuth0();
	return (
		<button
			className='button blue_style'
			onClick={() => {
				localStorage.removeItem('selectedLoan');
				localStorage.removeItem('hasVisitedSplashPage');
				logout({
					returnTo: `${window.location.origin}${process.env.REACT_APP_HOMEPAGE_PATH}`,
				})
			}}>
			Log Out
		</button>
	);
};

export default LogoutButton;
